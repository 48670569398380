import React from "react"
import styled from "styled-components"

const Body = styled.article`
  
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;

  h4 {
    margin-bottom: 24px;

    @media (min-width: 1280px) {
      margin-bottom: 32px;
    }
  }
  p {
    line-height: 150%;
  }

  @media (min-width: 1280px) {
    padding: 64px 104px;
  }

  .gatsby-resp-image-wrapper {
    margin-top: 0;
    margin-bottom: 32px;
  }

  @media (min-width: 550px) {
    padding: 56px 32px;
    .gatsby-resp-image-wrapper {
      float: right;
      width: 40%;
      margin-left: 32px !important;
    }
  }
`

export default function ServiceBody({ children }) {
  return <Body>{children}</Body>
}
