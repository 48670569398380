import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import ServiceHero from "../components/ServiceHero"
import ServiceBody from "../components/ServiceBody"
import Offer from "../components/Offer"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default function ServiceTemplate({ data }) {
  const {
    mdx: {
      frontmatter: { title, heroText },
      body,
    },
  } = data

  return (
    <Layout>
      <Seo title={title} description={heroText} />
      <ServiceHero title={title} heroText={heroText} />
      <ServiceBody>
        <MDXRenderer>{body}</MDXRenderer>
      </ServiceBody>

      <Offer />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        heroText
      }
      body
    }
  }
`
