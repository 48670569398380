import React from "react"
import styled from "styled-components"
import { colors } from "./Layout/colors"
import HeroBg from "../images/mobile-hero-bg.svg"
import HeroBgBig from "../images/mobile-hero-bg-big.svg"
import dotPattern from "../images/dot-pattern.svg"

const Hero = styled.section`
  position: relative;
  padding: 112px 32px 48px;
  background-image: url(${HeroBg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  > div {
    padding-left: 32px;
    padding-right: 32px;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 580px) {
    background-image: url(${HeroBgBig});
  }

  @media (min-width: 1024px) {
    padding: 160px 32px;
    background: linear-gradient(138.37deg, #2FC6E7 0%, #3371F2 153.28%);

    &::before {
      content: "";
      position: absolute;
      right: -33px;
      top: 37%;
      width: 116px;
      height: 116px;
      background: url(${dotPattern}) no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      left: 104px;
      bottom: -58px;
      width: 116px;
      height: 116px;
      background: url(${dotPattern}) no-repeat;
    }
  }

  @media (min-width: 1280px) {
    padding: 176px 104px;
  }
`

const Tagline = styled.p`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colors.text.white};
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`

const Title = styled.h4`
  margin-top: 24px;
  margin-bottom: 24px;
  color: ${colors.text.white};
  max-width: 984px;
  text-transform: capitalize;

  @media (min-width: 768px) {
    font-size: 3rem;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  @media (min-width: 1024px) {
    font-size: 4rem;
  }

  @media (min-width: 1280px) {
    font-size: 4.5rem;
  }
`

const Text = styled.p`
  font-size: 18px;
  line-height: 30px;
  margin: 0;
  color: ${colors.bg.lightB};
  max-width: 984px;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`

export default function ServiceHero({ title, heroText }) {
  return (
    <Hero>
      <div>
        <Tagline>our services</Tagline>
        <Title>{title}</Title>
        <Text>{heroText}</Text>
      </div>
    </Hero>
  )
}
